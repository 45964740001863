import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/layouts/simple-layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><a parentName="p" {...{
        "href": "/lesson-plans/"
      }}>{`»`}{` Back to Lesson Plans`}</a></p>
    <p>{`This week make `}<strong parentName="p">{`at least 5 substantive posts`}</strong>{` for this lesson's threads as follows:`}</p>
    <ul>
      <li parentName="ul">{`Reply to at least 3 of the numbered questions posed in the threads below `}<strong parentName="li">{`by 5pm Tuesday.`}</strong></li>
      <li parentName="ul">{`Then keep an eye on these and the other threads and respond to other students' posts or the professor's new questions with at least 2 more replies `}<strong parentName="li">{`by 5pm Friday.`}</strong></li>
    </ul>
    <h2>{`9.1 Maslow's Pyramid and UX`}</h2>
    <p>{`Select and describe one of the components of user experience Walter bases on Maslow’s pyramid.`}</p>
    <h2>{`9.2 Usable = Edible??`}</h2>
    <p>{`Walter describes 37signals’ design for the application Basecamp as highly usable and reliable. When he then says that usable is as good as being edible, what does he mean? What ways did sites like Wufoo and Betabrand distinguish themselves? Can you think of other examples?`}</p>
    <h2>{`9.3 Emotions and Memory`}</h2>
    <p>{`What is the relationship between emotions and memory?`}</p>
    <h2>{`9.4 Designing for Emotion as Christians`}</h2>
    <p>{`What does emotional design imply to you as a follower of Christ? Are there characteristics of godly living that relate? Are there areas of concern or conflict?`}</p>
    <h2>{`9.5 Designing for Humans`}</h2>
    <p>{`Choose one of the principles of designing for humans that Walter lays out in Chapter 2. Describe it and apply it to critique a specific site or application.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      